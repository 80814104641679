import React from "react"
import Base from "../components/base"
import Hero from "../components/hero"
import Teasers from "../components/teasers"
import {graphql, Link} from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Seo from "../components/Seo"

export const query = graphql`
    query($slug: String!) {
        bg: allContentfulOrderedMedia(limit: 1, sort: {fields: [order, createdAt], order: DESC}) {
            edges {
                node {
                    createdAt
                    image {
                        gatsbyImageData(layout: FIXED, sizes: "1920px")
                    }
                }
            }
        }
        site {
            siteMetadata {
                ...SiteMeta
            }
        }
        self: markdownRemark(fields: { slug: { eq: $slug } }) {
            excerpt (format: PLAIN)
            html
            frontmatter {
                ...FrontMatter
            }
            fields {
                slug
            }
            image {
                childImageSharp {
                    fluid (quality: 90, maxWidth: 700){
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
        teasers: allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}, limit: 3,
            filter: {fields: {slug: {ne: $slug}}}) {
            edges {node {
                fields {
                    slug
                }
                id
                html
                frontmatter {
                    ...FrontMatter
                }
                image {
                    childImageSharp {
                        fluid (quality: 90, maxWidth: 450) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
            }
        }
    }`


const Main = ({data, pageContext}) => {
  const isHome = pageContext.isHome;

  return (
  <Base data={data}>
    <Seo article image={isHome ? data.site.siteMetadata.defaultImage : data.self.image.childImageSharp.fluid.src}
         description={isHome ? data.site.siteMetadata.description : data.self.excerpt}
         title={isHome ? "Home" : data.self.frontmatter.title}
         pathname={isHome ? "/" : data.self.fields.slug}/>
    <Hero data={data.self}
          image={data.self.image.childImageSharp.fluid}
          siteMeta={data.site.siteMetadata}/>
      <Teasers headline={`~ ${isHome ? 'Weitere' : 'Neueste'} Episoden ~`}
               withImg teasers={data.teasers.edges}/>

    <Row className="my-5">
      <Col className="justify-content-center text-center">
        <Button variant="secondary"><Link to="/archiv" className="archive-link">Alle Episoden</Link></Button>
      </Col>
    </Row>
  </Base>
  )

}
export default Main;

